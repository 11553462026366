// To override main css use !important after variables
// After that compile main.sass

$primary-bootstrap-button: $primary-color
$primary-bootstrap-button-text: $light-color !important
$plus-minus-color: $dark-color !important

//ACCORDIONS VARIABLE
$accordion-button-color: $bg-color !important
$accordion-button-text-color: $primary-color !important

//ALERT
$position-fixed: fixed !important
$max-z-index: 999 !important

//HEADER
$header-background-links: $dark-color !important
$header-links-color: $text-color !important
$header-welcome-text-color: $dark-color !important
$header-cart-counter-color: $red-color
$mobile-main-navigation-background: $light-color
$mobile-main-navigation-color: $dark-color
$header-welcome-text-background: $dark-color !important

//FOOTER
$footer-background-color: $dark-color !important
$footer-color: $text-color !important

//CART COMPONENT
$cart-header-background: $light-color !important
$cart-header-total-color: $primary-color !important
$cart-lnkCartUpdate-background: $warning-bootstrap-color !important
$cart-lnkCartContinueShopping-background: $blue-bootstrap-color !important
$cart-lnkCartClearCart-background: $danger-bootstrap-color !important

//Wish list
$wish-list-header-background-color: $light-color
$wish-list-header-total-color: $light-color

$quick-order-remove-product-icon-color: $red-color
$quick-order-cancel-button-color: $dark-color

//Virtual Toolbox
$s-smallSized-1: 43px !important
$smallSized-1: 51px !important
$s-mediumSized-1: 60px !important
$mediumSized-1: 83px !important
$s-largeSized-1: 145px !important
$largeSized-1: 191px !important

$s-smallSized-2: 30px !important
$smallSized-2: 50px !important
$s-mediumSized-2: 65px !important
$mediumSized-2: 95px !important
$s-largeSized-2: 150px !important
$largeSized-2: 205px !important

$s-smallSized-3: 30px !important
$smallSized-3: 45px !important
$s-mediumSized-3: 60px !important
$mediumSized-3: 80px !important
$s-largeSized-3: 145px !important
$largeSized-3: 205px !important